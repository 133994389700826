.termContainer {
    height: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
}

.termContainer p:nth-child(2) {
    font-size: 21px;
    margin-top: 15px;
    width: 700px;
}

@media screen and (max-width:992px){
    .termContainer p:nth-child(2) {
        width: 500px;
    }
}

@media screen and (max-width:640px){
    .termContainer p:nth-child(2) {
        width: 350px;
    }
}