.hamburger-lines {
    display:none;
}

.rNavLinksContainer {
    display:none;
}

.navLogoRes {
    display: none;
    position: absolute;
    top:10px;
    left:50px;
}

.navLogoRes img {
    width: 200px;
}


@media screen and (max-width:992px){

    .rNavLinksContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
    }

    .hamburger-lines {
        display: block;
        height: 26px;
        width: 32px;
        position: absolute;
        top: 25px;
        right: 20px;
        z-index: 1001;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
    }

    .hamburger-lines .line {
        display: block;
        height: 4px;
        width: 100%;
        border-radius: 10px;
        background: #0e2431;
    }

    .hamburger-lines .line1 {
        transform-origin: 0% 0%;
        transition: transform 0.4s ease-in-out;
    }
    
    .hamburger-lines .line2 {
        transition: transform 0.2s ease-in-out;
    }
    
    .hamburger-lines .line3 {
        transform-origin: 0% 100%;
        transition: transform 0.4s ease-in-out;
    }
    

    .openHamburger-lines .line1 {
        transform: rotate(45deg);
        background: #fff;
    }
    
    .openHamburger-lines .line2 {
        transform: scaleY(0);
        background: #fff;
    }
    
    .openHamburger-lines .line3 {
        transform: rotate(-45deg);
        background: #fff;
    }

    .Navbar-popup-menu {
        position: fixed;
        z-index: 1000;
        width: 100%;
        height: 100%;
        background: #122141;
        display: grid;
        align-items: center;
        top: 0;
        left: 0;
        transition: 0.3s ease-in-out; 
        opacity: 100%;
        top: 0;
    }

    .Navbar-popup-menu-off {
        opacity: 0;
        top: -100%;
    }

    .Navbar-links-popup-menu {
        height: 80vh;
    }

    .rNavLinks {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.5rem;
        text-decoration: none;
        list-style: none;
        transition: 0.2s ease-in-out;
        color: #fff;
        cursor: pointer;
        margin: 18px 0;
        width: 150px;
    }

    .rNavLinks:hover {
        color: #F7941D;
        transition: 0.2s ease-in-out;
    }

    .offOpacity {
        opacity: 0;
    }

    .fixHamb {
        position: fixed;
        top: 17px;
        right: 20px;
    }

    .navLogoRes {
        display: inline;
        position: absolute;
        top:10px;
        left:50px;
    }

    .navLogoRes img {
        width: 200px;
    }

}