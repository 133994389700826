.testimonyCardContainer {
    display:flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    height:325px;
    width: 500px;
    /* border: 1px solid gray; */
    /* margin-bottom: 25px; */
    background-color: white;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin: 10px;
}

.testimonyInfoContainer {
    display:flex;
    flex-direction: column;
    align-items: center;
    height:255px;
    width: 280px;
    /* border: 1px solid gray; */
    /* margin-bottom: 25px; */
    /* background-color: white; */
    border-radius: 5px;
    padding: 15px 0;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    margin: 2px;
    font-size: 13px;
}

.testimonyQuoteStars {
    display: flex;
    justify-content: center;
    /* border: 1px solid red; */
    letter-spacing: .9cap;
    /* letter-spacing: .5cap;
    height: 70px;
    flex-direction: row;
    margin-left: 15px;
    justify-content: center;*/
    font-size: 26px;
}


.testimonyParagraphContainer {
    display:flex;
    flex-direction: column;
    align-items: center;
    height: 400px;
    /* border: 1px solid red; */
}

.testimonyParagraphContainer p:nth-child(1) {
    /* border: 1px solid green; */
    width: 100%;
    height: 100%;
    position: relative;
    top: 10px;
    font-style: italic;
    line-height: 150%;
    padding: 0 5px;
}

.testimonyPersonContainer {
    /* border: 1px solid purple; */
    width: 100%;
    display: flex;
    flex-direction: column;
}

.testimonyPersonContainer div {
    /* border: 1px solid green; */
    /* width: px; */
    align-self: flex-end;
    margin-right:10px;
}

.testimonyPersonContainer p:nth-child(1) {
    font-weight: 900;
    color: rgb(37, 37, 37)
    /* color: pink; */
}

.testimonyPersonContainer p:nth-child(2) {
    font-weight: normal;
    color: rgb(125, 124, 124)
    /* color: pink; */
}

.reviewLeaf2 {
    /* border: 1px solid red; */
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}