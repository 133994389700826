
.navBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FFFFFF;
    height:80px;
    box-shadow:0 .2px 5px rgb(97, 97, 97);
    position: sticky;
    top:0;
    z-index: 1;
    padding: 0 50px;
}

.navLogo {
    margin-left: 50px;
    font-weight: bold;
    /* background-color: yellow; */
}

.navLinksContainer {
    width: 800px;
    display: flex;
    justify-content: flex-end;
    font-size: 17px;
    font-weight: 500;
    /* background-color: aqua; */
}

.navLinks {
    color: #F7941D;
    margin: 0 20px;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
}

.navLinks:hover {
    color: black;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
}

.navLinksActive {
    color: black;
    margin: 0 20px;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
}

.navLinksActive:hover {
    margin: 0 20px;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
}


.headroom {
    will-change: transform;
    transition: transform 200ms linear;
}

.headroom--pinned {
    transform: translateY(0%);
}

.headroom--unpinned {
    transform: translateY(-100%);
}

@media screen and (max-width:992px){
    .navLinksContainer {
        transition: 0.8s all ease;
        display:none;
    }    
}

@media screen and (max-width:640px){
    .navLogo {
        margin-left: 0;
    } 

    .navLogo img {
        width: 200px;
    }
}
