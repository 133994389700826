.footerContainer {
    width: 100%;
    height: 100%;
    color: white;
    font-weight: bold;
    background-color: #0C1529;
    padding: 100px 0;
}

.footerGridContainer {
    display: grid;
    justify-content: center;
    margin-left: 50px;
    column-gap: 50px;
    row-gap: 50px;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto;
    grid-template-areas: "sec1 sec2 sec3 sec4";
    
}

.gridItems {
    width: 175px;
    height: 200px;
}

.grid1 {
    grid-area: sec1;
    width: 300px;   
}

.grid1 p {
    font-weight: normal;
    font-size: 16px;
    line-height: 1.8;
    color: rgb(233, 232, 232)
}

.grid2 {
    grid-area: sec2;   
}

.grid3 {
    grid-area: sec3;   
}

.grid4 {
    grid-area: sec4;
    width: 265px;  
}

.logoFooter {
    width: 250px;
}

.footerLinks {
    font-size: 18px;
    letter-spacing: 1px;
}

.footerLinksBorder {
    margin-top: 10px;
    margin-bottom: 25px;
    border-bottom: 5px solid #F7941D;
    width: 50px;
}

.footerList {
    list-style-type:none;
}



.footerLink {
    text-decoration: none;
    font-size: 17px;
    font-weight: normal;
    transition: 0.2s ease-in-out;
    color: white;
}

.footerLinkIcon {
    font-size: 23px;
    color: #F7941D;
    margin-right:5px;
    transition: 0.2s ease-in-out;
    transform: rotatey(180deg);
}

.footerList li {
    margin: 20px 0;
    display:flex;
    align-items: flex-end;
}

.contactAnchorFooter {
    display: flex;
    text-decoration: none;
}

.footerLinkHover {
    transition: 0.2s ease-in-out;
    color:#F7941D;
}

.footerLinkIconHovered {
    transition: 0.2s ease-in-out;
    transform: rotateY(360deg);
}

.footerBottomInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #091122;
    height: 50px;
    font-weight: normal;
    font-size: 11px;
    color:white;
    padding: 0 20px;
}

.footerLogoBottom {
    color: #F7941D;
    text-decoration: none;
    margin-left: 2px;
}


.footerBottomLinks {
    text-decoration: none;
    color: white;
    margin: 0 5px;
}

.footerLink-cards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 230px;
}

.footerLink-cards > span {
    color: #F7941D;
}

.footer-card1 {
    font-size: 33px;
}

.footer-card2 {
    font-size: 32px;
}

.footer-card3 {
    font-size: 25px;
}

.footer-card4 {
    font-size: 33px;
}

@media screen and (max-width:1130px){
    .footerGridContainer {
        grid-template-columns: auto auto;
        grid-template-areas: "sec1 sec2"
                              "sec3 sec4"; 
    }
}

@media screen and (max-width:766px){
    .grid1 {
        height:100px !important;
    }

    .footerGridContainer {
        
        grid-template-columns: auto ;
        grid-template-areas:  "sec1" 
                              "sec2"
                              "sec3" 
                              "sec4"; 
    }
}

@media screen and (max-width:500px){
    

    .footerGridContainer {
        
        grid-template-columns: auto ;
        grid-template-areas:  "sec1" 
                              "sec2"
                              "sec3" 
                              "sec4"; 
    }

    .footerBottomInfo {
        flex-direction: column;
    }

    .footerBottomInfo div:nth-child(1) {
        margin-top: 10px;
    }

    .footerBottomInfo div:nth-child(2) {
        margin-bottom: 10px;
    }
}