.homeH1 {
    font-size: 120px;
}

.homeHeroContainer {
    width: 100%;
	height: 800px;
    /* border: 4px solid red; */
}

.heroContainer {
    height: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("ratpic.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    opacity: .9;
}

.testimonySection {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    width:100%;
    height: 400px;
    background-color: rgb(222, 224, 232);

}

.heroTextContainer {
    position: relative;
    top: 150px;
    left: 100px;
    width: 750px;
}

.heroTextContainer p:nth-child(1) {
    color: #F7941D;
    font-weight: bold;
    font-size: 18px;
}

.heroTextContainer p:nth-child(2) {
    color: white;
    font-weight: bold;
    font-size: 60px;
    margin-top: 15px;
    margin-bottom: 25px;
    letter-spacing: 1px;
}

.heroTextContainer span {
    color: #F7941D;
}

.heroTextContainer p:nth-child(3) {
    color: white;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.8;
    letter-spacing: .5px;
}

.homeCardSection {
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.heroButtons {
    margin-top: 65px;
}
.heroButton:nth-child(1) {
    text-decoration: none;
    background-color: #F7941D;
    color: white;
    padding:15px 30px;
    border-radius: 30px;
}

.heroButton:nth-child(2) {
    text-decoration: none;
    background-color: #0C1529;
    color: white;
    padding:15px 30px;
    border-radius: 30px;
    margin-left: 30px;
}

.homeCardSection {
    display: flex;
    flex-wrap: wrap;
    height:100%;
    margin: 35px 0;
}

.homeCard {
    background-color: #F7941D;
    color: white;
    height: 300px;
    width: 300px;
    border-radius: 10px;
    margin: 20px 20px;
}

.homeCard {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: flex-start;
    padding:25px;
}

.homeCardIcon {
    font-size: 65px;
}

.homeCard p:nth-child(2) {
    margin-top:10px;
    font-size: 25px;
}


.homeCard p:nth-child(3) {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.8;
    margin: 10px 0 0 0;
}

.homeCardSeeServices {
    font-size: 12px;
    color: white;
}

@media screen and (max-width:1159px){


    .testimonySection {
        height: 800px;
    }
}

@media screen and (max-width:992px){
    .homeHeroContainer {
        height: 550px;
        /* border: 4px solid red; */
    }

    .heroTextContainer {width: 510px;}
    .heroTextContainer p:nth-child(1) {font-size: 15px;}
    .heroTextContainer p:nth-child(2) {font-size: 40px;}
    .heroTextContainer p:nth-child(3) {font-size: 14px;}
}


@media screen and (max-width:778px){
    .testimonySection {
        height: 1200px;
    }
}

@media screen and (max-width:640px){
    .heroContainer {
        height: 500px;
        /* border: 4px solid red; */
        display: flex;
        justify-content: center;
    }

    .heroTextContainer {
        width: 350px;
        height: 300px;
        left: 0;
        top: 80px;
    }

    .heroTextContainer p:nth-child(1) {font-size: 12px;}
    .heroTextContainer p:nth-child(2) {font-size: 27px;}
    .heroTextContainer p:nth-child(3) {font-size: 10px;}


    .testimonySection {
        height: 1150px;
    }

   
}


