.contactContainer {
    margin-left: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.contact-map-container {
    height: 525px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.contact-map {
    width: 650px;
    height: 400px;
    margin:25px 0;
    color: #F7941D;
    font-size: 18px;
    font-weight: bold;
}

.contact-map-iframe {
    width: 650px;
    height: 400px;
    margin: 8px 0 25px 0;
    border:2px solid #F7941D;
    border-radius: 5px;
}


.contactFormContainer {
    margin-top:25px;
    padding: 0 25px 25px 25px;
    width:500px;
}


.contactFormHeader p:nth-child(1) {
    color: #F7941D;
    font-size: 18px;
    font-weight: bold;
}

.contactFormHeader p:nth-child(2) {
    font-size: 42px;
    font-weight: 900;
    line-height: 1.3;
}

.contactFormHeader p:nth-child(3) {
    font-size: 16px;
    margin-bottom: 15px;
}

.contactName {
    display: flex;
}

.contactName input {
    height: 35px;
    width: 435px;
    margin-right: 15px;
    margin-bottom: 15px;
    border-radius: 7px;
    border: none;
    border: 1px solid gray;
    color: gray;
    padding:10px;
}



.contactPhoneEmail {
    display: flex;
}

.contactPhoneEmail input {
    height: 35px;
    width: 210px;
    margin-right: 15px;
    margin-bottom: 15px;
    border-radius: 7px;
    border: none;
    border: 1px solid gray;
    color: gray;
    padding:10px;
}

.contactQuestions input {
    height: 35px;
    width: 435px;
    border-radius: 7px;
    border: none;
    border: 1px solid gray;
    color: gray;
    padding:10px;
    margin-bottom: 15px;
}

.contactTextAreaDiv textarea {
    width: 435px;
    height: 120px;
    border-radius: 7px;
    border:none;
    border: 1px solid gray;
    padding:10px;
    margin-bottom: 20px;
    color: gray;
    resize: none;
}


.contactSubmitButton {
    text-decoration: none;
    background-color: #F7941D;
    color: white;
    padding:12px 28px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
}

.disabledSubmit {
    cursor:default;
    background-color: #f1cfa5;
}


/* This is for grid when 4 pieces of info is ready */
.contactBottomSquaresContainer {
    margin-left:50px;
    margin-top: 50px;
    height: 150px;
    display: grid;
    justify-items: flex-start;
    column-gap: 15px;
    row-gap: 0;
    overflow: hidden;
    grid-template-columns: auto;
    grid-template-areas: "square1"
                         "square2";
                         /* "square3" 
                         "square4"; */
}

/* 
.contactBottomSquaresContainer {
    margin: 25px 0 0 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
} */

.contactSquares {
    display: flex;
    align-items: center;
    justify-content: center; 
}

.contactSquareIcon {
    width: 50px;
    height: 50px;
    background-color: #F7941D;
    font-size: 35px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin:10px;
}

.contactAnchorContact {
    text-decoration: none;
    color:rgb(10, 10, 10)
}

.contactSquareText {margin: 5px;}

.contactSquareText p:nth-child(1) {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.8;
}

.contactSquareText p:nth-child(2) {
    font-size: 11px;
    font-weight: normal;
    color:rgb(81, 81, 81)
}

.contactRobotCheckContainer {
    display:flex;
    align-items: center;
    margin-bottom: 15px;
}

.contactRobotCheckContainer p { 
    margin-right:10px;
}

.loaderContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    justify-content: center;
    height:200px;
}

.loader {
    border: 16px solid #161616;
    border-radius: 50%;
    border-top: 16px solid #F7941D;
    /* border-right: 16px solid orange; */
    border-bottom: 16px solid #F7941D;
    /* border-left: 16px solid white; */
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

@media screen and (max-width:992px){
    .contactContainer {
        flex-direction: column;
    }

    .contactPhoneEmail {
        flex-direction: column;
    }

    .contactPhoneEmail input {
        width: 435px;
    }

    /* .contactBottomSquaresContainer {
        margin-left: 20px;
        margin-top: 20px;
        margin: 20px 0 20px 20px;
        flex-direction: row;
    }  */

    .contactSquares {
        margin-right: 25px;
    }

    .contactBottomSquaresContainer {
        margin-left: 20px;
        margin-top: 5px;
        width: 500px;
        height: 130px;
        grid-template-areas: "square1 square2";                         
    }

    .contact-map-container {
        height: 525px;
        width: 500px;
        display: flex;
        justify-content: start;
        margin-left:75px;
    }
    
    .contact-map {
        width: 500px;
        height: 400px;
        margin:25px 0;
        color: #F7941D;
        font-size: 18px;
        font-weight: bold;
    }
    
    .contact-map-iframe {
        width: 500px;
        height: 400px;
        margin: 8px 0 25px 0;
    }
}

@media screen and (max-width:640px){
    .contactContainer {
        margin-left: 0;
        flex-direction: column;
    }

    .contactFormContainer {
        width: 100%;
    }

    .contactFormHeader p:nth-child(2) {
        width: 320px;
    }
    
    .contactFormHeader p:nth-child(3) {
        width: 320px;
        font-size: 12px;
    }
    

    .contactName {
        flex-direction: column;
    }

    .contactName input {
        width: 320px;

    }

    .contactName input:nth-child(1) {
        width: 320px;
        margin-bottom: 15px;
    }

    .contactPhoneEmail input {
        width: 320px;
    }

    .contactQuestions input {
        width: 320px;
    }

    .contactTextAreaDiv textarea {
        width: 320px;
    }

    /* .contactBottomSquaresContainer {
        margin: 20px 0 20px 20px;
        flex-direction: column;
    }  */

    .contactSquares {
        margin-right: 0;
    }

    .contactBottomSquaresContainer {
        width: 300px;
        grid-template-areas: "square1"
                             "square2";
    } 

    .contact-map-container {
        height: 525px;
        width: 400px;
        display: flex;
        justify-content: start;
        margin-left:30px;
    }
    
    .contact-map {
        width: 400px;
        height: 400px;
        margin:25px 0;
        color: #F7941D;
        font-size: 18px;
        font-weight: bold;
    }
    
    .contact-map-iframe {
        width: 400px;
        height: 400px;
        margin: 8px 0 25px 0;
    }

}


@media screen and (max-width:480px){
    .contact-map-container {
        width: 325px;
    }
    
    .contact-map {
        width: 325px;
    }
    
    .contact-map-iframe {
        width: 325px;
    }

}