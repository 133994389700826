.accessContainer {
    height: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
}

.accessTextContainer {
    width: 700px;
}

.accessTextTitle {
    font-size: 24px;
    margin-top: 15px;
    margin-bottom: 10px;
    color: rgb(27, 27, 27);
}

.accessContactInfo {
    display: flex;
    width: 100px;
    flex-direction: column;
    margin: 15px 0;
}

.accessContactInfo div:nth-child(3) {
    margin: 12px 0;
}


.accessContactInfo div:nth-child(7) {
    margin: 12px 0;
}

@media screen and (max-width:992px){
    .accessTextContainer {
        width: 500px;
    }
}

@media screen and (max-width:640px){
    .accessTextContainer {
        width: 350px;
    }

    .accessTextTitle {
        font-size: 20px;
        margin-bottom: 10px;
    }
}