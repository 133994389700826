@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
    font-family: 'Roboto', sans-serif;
	background: rgb(30, 29, 29);
}

.errorPage {
	width: 100%;
	height: 300px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	/* border: 1px solid red; */
	color: white;
}

.errorPage > h1 {
	font-size: 120px;
}

.errorPage > h3 {
	font-size: 24px;
}