.backToTopContainer {
    width: 75px;
    height:75px;
    background-color: rgba(255,165,0,.7);
    position: fixed;
    z-index: 999;
    bottom: 35px;
    right: 20px;
    display:flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
}

.toTopArrow {
    font-size: 50px;
    font-weight: bold;
    color: white;
    /* background-color: black; */
}

.backToTopContainer p {
    color:white;
}

.showTop {
    opacity: 1;
    transition: .25s ease-in-out;

}

.hideTop {
    opacity: 0;
    transition: .25s ease-in-out;
}