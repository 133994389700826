.aboutContainer {
    margin: 50px;
    display: flex;
    justify-content: center;
}



.aboutButton {
    text-decoration: none;
    background-color: #F7941D;
    color: white;
    padding:12px 28px;
    border-radius: 30px;
}

.aboutPic {
    margin-right: 20px;
    border-radius: 20px;
    box-shadow: 4px 4px 10px rgba(97, 97, 97,.2);
    object-fit: cover;
}

.aboutUsSection {
    width: 400px;
    height: 600px;
    margin-left: 20px;
    overflow: hidden;
}

.aboutOffWords {
    color: #F7941D;
}

.aboutUsSection p:nth-child(1) {
    font-size: 18px;
    font-weight:700;
    color: #F7941D;
    margin-bottom: 7px;
}

.aboutUsSection p:nth-child(2) {
    font-size: 42px;
    font-weight: 900;
    line-height: 1.1;
}

.aboutUsSection p:nth-child(3) {
    font-size: 14px;
    font-weight:400;
    line-height: 1.8;
    color:rgb(126, 126, 126);
    margin-top:10px;
}

.aboutUsSection p:nth-child(4) {
    font-size: 14px;
    font-weight:400;
    line-height: 1.8;
    color:rgb(126, 126, 126);
    margin-top:10px;
}


.azItalics {
    font-style: italic;
    font-size: 14px;
    font-weight:500;
    line-height: 1.8;
    color:rgb(126, 126, 126);
}

.aboutUsSection p:nth-child(5) {
    font-size: 14px;
    font-weight:400;
    line-height: 1.8;
    color:rgb(126, 126, 126);
    margin-top:10px;
}

.aboutUsSection ul {
    margin: 20px 0 40px 0;
    font-weight:400;
    line-height: 1.3;
    color:rgb(126, 126, 126);
}

.aboutUsSection li {
    display: flex;   
    list-style-type: none;
    font-size: 14px;
}

.aboutCheck  {
    font-size: 20px;
    color: #F7941D;
}

@media screen and (max-width:992px){
    .aboutContainer {
        flex-direction: column;
        align-items: center;
    }

    .aboutUsSection {
        width: 335px;
        margin-left: 20px;
    }

    .aboutPic {
        margin-right: 20px;
        margin-bottom: 20px;
        border-radius: 20px;
        box-shadow: 4px 4px 10px rgba(97, 97, 97,.2);
        width: 475px;
        height: 375px;
        object-position: 0px -50px;
    }
}

@media screen and (max-width:640px){
    
    .aboutPic {
        width: 300px;
        height: 200px;
    }
}