.topInfo {
    display:flex;
    align-items: center;
    justify-content: flex-start;
    height:45px;
    padding-left: 80px;
    background-color: #0C1529;
    color: #FFFFFF;
}

.topInfo div {
    display:flex;
    align-items: center;
}

div > .topInfoIconFirst {
    color: #F7941D;
    margin-right: 7px;
    margin-left: 0;
}

div > .topInfoIcon {
    color: #F7941D;
    margin-right: 7px;
    margin-left:25px;
}

.contactAnchor > .topInfoIcon {
    color: #F7941D;
    margin-right: 7px;
    margin-left:25px;
}

.contactAnchor {
    color: #FFFFFF;
    text-decoration: none;
}


@media screen and (max-width:742px){
    .topInfo {
        transition: 0.1s all ease;
        display:none;
    }    
}