.titleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 130px;
    padding-top: 20px;
}

.titleContainer p:nth-child(1) {
    color: #F7941D;
    font-size: 18px;
    font-weight: bold;
}

.titleContainer p:nth-child(2) {
    font-weight: bolder;
    font-size: 42px;
}

.titleContainer p:nth-child(3) {
    font-size: 16px;
}

.titleOffWord {
    color: #F7941D;
}

.servicesCardsContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 0 30px 0;
    overflow: hidden;
}



.servicesCardContainer {
    background-color: whitesmoke;
    width: 320px;
    height: 370px;
    border-radius: 20px;
    box-shadow: 4px 4px 10px rgba(97, 97, 97,.2);
    margin: 25px 15px;
    overflow: hidden;
}

.servicesCardImgBoxInspection {
    width: 100%;
    height: 200px;
    border-radius: 20px;
    background-image: url("./inspectionPic.jpg");
    background-position: 0px -110px;
    background-repeat: no-repeat;
    background-size: 350px 450px;
}

.servicesCardImgBoxElimnation {
    width: 100%;
    height: 200px;
    border-radius: 20px;
    background-image: url("./eliminationPic.jpg");
    background-position: 0px -110px;
    background-repeat: no-repeat;
    background-size: 350px 450px;
}

.servicesCardImgBoxAttic {
    width: 100%;
    height: 200px;
    border-radius: 20px;
    background-image: url("./atticPic.jpg");
    background-position: 0px -160px;
    background-repeat: no-repeat;
    background-size: 350px 450px;
}

/* .servicesCardContainer img {
    height:200px;
    width: 100%;
    border-radius: 20px;

} */

.servicesCardContainer p:nth-child(2) {
    font-weight: 700;
    font-size:18px;
    line-height: 1.8;
    padding: 0 18px;
}

.servicesCardContainer p:nth-child(3) {
    font-weight: 700;
    font-size:14px;
    line-height: 1.5;
    color:rgb(126, 126, 126);
    padding: 0 18px;
}

.servicesCardContainer p:nth-child(4) {
    font-weight: 700;
    font-size:14px;
    color:#F7941D;
    padding: 15px 18px 0 18px;
    cursor: pointer;
}

.serviceCardAndUp {
    font-size: 9px;
}

.serviceHR {
    background-color: white;
    color: white;
    border-style: none none dotted;
    border-bottom: 9px dotted #F7941D;
    margin: 50px;
}

.servicesLearnMoreContainer {
    margin: 50px;
}

.slmT {
    font-weight: 700;
    font-size:18px;
    line-height: 1.8;
    margin: 15px 0 0 0;
}

.slmP {
    font-weight: normal;
    color:rgb(126, 126, 126);
    font-size:14px;
    line-height: 1.2;
    margin: 0 0 10px 0;
}

.serviceLearnMore ul {
    list-style-type:none;
    font-weight: normal;
    color:rgb(126, 126, 126);
    font-size:14px;
    line-height: 1.8;
}


.serviceLearnMore li {
    display: flex;    
}

.serviceCheck  {
    font-size: 20px;
    color: #F7941D;
}

.slmImg {
    width: 400px;
    height: 300px;
    border-radius: 20px;
}

@media screen and (max-width:992px){
    .slmImg {
        width: 300px;
        height: 200px;
    }
}

@media screen and (max-width:640px){
    .slmImg {
        width: 275px;
        height: 220px;
    }
}


